import 'firebase/auth';

import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import * as firebase from 'firebase/app';
import {Observable} from 'rxjs';
import {FirebaseService} from '../service/firebase.service';

@Injectable({providedIn: 'root'})
export class AdminGuard implements CanActivate {
  constructor(
      private afAuth: AngularFireAuth, private router: Router,
      public firebaseService: FirebaseService) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
      Observable<boolean>|Promise<boolean>|boolean {
    return new Promise(async (resolve, reject) => {
      await firebase.auth().onAuthStateChanged(user => {
        if (firebase.auth().currentUser) {
          return firebase.auth()
              .currentUser.getIdTokenResult()
              .then((idTokenResult) => {
                // Confirm the user is an Admin.
                if (!!idTokenResult.claims.admin) {
                  // Show admin UI.
                  resolve(true);
                } else {
                  // Show regular user UI.
                  this.router.navigate(['/home']);
                  resolve(false);
                }
              })
              .catch((error) => {
                this.router.navigate(['/home']);
                console.log(error);
              });
        }
        resolve(false);
      });
    });
  }
}
