import {Injectable} from '@angular/core';
import {GooglePlus} from '@ionic-native/google-plus/ngx';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {SafariViewController} from '@ionic-native/safari-view-controller/ngx';
import {Platform} from '@ionic/angular';

@Injectable({providedIn: 'root'})
export class NativeService {
  // Network
  isOnline = true;
  // Device
  appCode;
  appVersions;
  devices: any = new Object();
  uuid: string;
  constructor(
      public googlePlus: GooglePlus,
      private safariViewController: SafariViewController,
      private iab: InAppBrowser, public platform: Platform) {}
  // リンク先をアプリ内ブラウザで開く(iosの場合_systemで開く時はnotSafariViewをTrueにする)
  safariView(url, notSafariView = false) {
    this.platform.ready().then(() => {
      if (!url) {
        return;
      }
      if (this.platform.is('cordova')) {
        if (notSafariView || this.platform.is('android')) {
          return this.iab.create(url, '_system');
        }
        this.safariViewController.isAvailable().then((available: boolean) => {
          if (available) {
            this.safariViewController
                .show({
                  url: url,
                  hidden: false,
                  animated: false,
                  transition: 'curl',
                  enterReaderModeIfAvailable: true
                })
                .subscribe((result: any) => {
                  if (result.event === 'opened') {
                    console.log('Opened');
                  } else if (result.event === 'loaded') {
                    console.log('Loaded');
                  } else if (result.event === 'closed') {
                    console.log('Closed');
                  }
                }, (error: any) => console.error(error));
          } else {
            // use fallback browser, example InAppBrowser
          }
        });
      } else {
        notSafariView ? window.open(url, '_system') :
                        window.open(url, '_blank');
      }
    });
  }
}
