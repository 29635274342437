export class AnalyticsOutput {}
export class AnalyticsTime {
  charts: any;
  aggregateDatas: object = {
    allTime: {
      workTime: {max: 0, min: 0, average: 0, median: 0},
      restTime: {max: 0, min: 0, average: 0, median: 0},
      strokeCount: {max: 0, min: 0, average: 0, median: 0}
    },
    firstTime: {
      workTime: {max: 0, min: 0, average: 0, median: 0},
      restTime: {max: 0, min: 0, average: 0, median: 0},
      strokeCount: {max: 0, min: 0, average: 0, median: 0}
    },
    latterTime: {
      workTime: {max: 0, min: 0, average: 0, median: 0},
      restTime: {max: 0, min: 0, average: 0, median: 0},
      strokeCount: {max: 0, min: 0, average: 0, median: 0}
    }
  };
  // チャート //

  chartColors = {
    red: 'rgb(255, 99, 132)',
    orange: 'rgb(255, 159, 64)',
    yellow: 'rgb(255, 205, 86)',
    green: 'rgb(75, 192, 192)',
    blue: 'rgb(54, 162, 235)',
    purple: 'rgb(153, 102, 255)',
    grey: 'rgb(231,233,237)',
    lime: 'rgb(169,204,81)',
    pink: 'rgb(228,77,147)',
    sky: 'rgb(0,167,219)',
    red_a2: 'rgba(255, 99, 132,0.2)',
    orange_a2: 'rgba(255, 159, 64,0.2)',
    yellow_a2: 'rgba(255, 205, 86,0.2)',
    green_a2: 'rgba(75, 192, 192,0.2)',
    blue_a2: 'rgba(54, 162, 235,0.2)',
    purple_a2: 'rgba(153, 102, 255,0.2)',
    grey_a2: 'rgba(231,233,237,0.2)',
    lime_a2: 'rgba(169,204,81,0.2)',
    pink_a2: 'rgba(228,77,147,0.2)',
    sky_a2: 'rgba(0,167,219,0.2)',
    clear: 'rgba(255,255,255,0)'
  };
  timeDatas: any = [];
  setTimeOutput(timeDatas) {
    for (let index = 0; index < timeDatas.length; index++) {
      timeDatas[index].rallyNumber = index + 1;
    }
    this.timeDatas = timeDatas;
    return this.setChart();
    // { allTime:, firstTime:, latterTime:};
  }
  setChart() {
    if (!this.timeDatas.length) {
      return null;
    }
    return this.setTimeChart();
  }
  aggregateTime(data) {
    const datas = JSON.parse(JSON.stringify(data));
    const max = arr => {
      if (!arr.length) {
        return 0;
      }
      return Math.max.apply(null, arr);
    };
    const min = arr => {
      if (!arr.length) {
        return 0;
      }
      return Math.min.apply(null, arr);
    };
    const sum = arr => {
      if (!arr.length) {
        return 0;
      }
      return arr.reduce((prev, current) => {
        return prev + current;
      });
    };
    const average = arr => {
      if (!arr.length) {
        return 0;
      }
      return sum(arr) / arr.length;
    };
    const median = arr => {
      if (!arr.length) {
        return 0;
      }
      const half = arr.length / 2 || 0;
      const temp = arr.sort();
      if (temp.length % 2) {
        return temp[half];
      }
      return (temp[half - 1] + temp[half]) / 2;
    };
    datas.allTime['excludeRestTime'].filter((value, index) => {
      if (value) {
        datas.allTime['restTime'][index] = 0;
      }
    });
    datas.firstTime['excludeRestTime'].filter((value, index) => {
      if (value) {
        datas.firstTime['restTime'][index] = 0;
      }
    });
    datas.latterTime['excludeRestTime'].filter((value, index) => {
      if (value) {
        datas.latterTime['restTime'][index] = 0;
      }
    });
    datas.allTime['restTime'] = datas.allTime['restTime'].filter(value => {
      return value !== 0;
    });
    datas.firstTime['restTime'] = datas.firstTime['restTime'].filter(value => {
      return value !== 0;
    });
    datas.latterTime['restTime'] =
        datas.latterTime['restTime'].filter(value => {
          return value !== 0;
        });
    // 要素を削除する
    return {
      allTime: {
        workTime: {
          sum: sum(datas.allTime['workTime']),
          max: max(datas.allTime['workTime']),
          min: min(datas.allTime['workTime']),
          average: average(datas.allTime['workTime']),
          median: median(datas.allTime['workTime'])
        },
        restTime: {
          sum: sum(datas.allTime['restTime']),
          max: max(datas.allTime['restTime']),
          min: min(datas.allTime['restTime']),
          average: average(datas.allTime['restTime']),
          median: median(datas.allTime['restTime'])
        },
        strokeCount: {
          sum: sum(datas.allTime['strokeCount']),
          max: max(datas.allTime['strokeCount']),
          min: min(datas.allTime['strokeCount']),
          average: average(datas.allTime['strokeCount']),
          median: median(datas.allTime['strokeCount'])
        }
      },
      firstTime: {
        workTime: {
          sum: sum(datas.firstTime['workTime']),
          max: max(datas.firstTime['workTime']),
          min: min(datas.firstTime['workTime']),
          average: average(datas.firstTime['workTime']),
          median: median(datas.firstTime['workTime'])
        },
        restTime: {
          sum: sum(datas.firstTime['restTime']),
          max: max(datas.firstTime['restTime']),
          min: min(datas.firstTime['restTime']),
          average: average(datas.firstTime['restTime']),
          median: median(datas.firstTime['restTime'])
        },
        strokeCount: {
          sum: sum(datas.firstTime['strokeCount']),
          max: max(datas.firstTime['strokeCount']),
          min: min(datas.firstTime['strokeCount']),
          average: average(datas.firstTime['strokeCount']),
          median: median(datas.firstTime['strokeCount'])
        }
      },
      latterTime: {
        workTime: {
          sum: sum(datas.latterTime['workTime']),
          max: max(datas.latterTime['workTime']),
          min: min(datas.latterTime['workTime']),
          average: average(datas.latterTime['workTime']),
          median: median(datas.latterTime['workTime'])
        },
        restTime: {
          sum: sum(datas.latterTime['restTime']),
          max: max(datas.latterTime['restTime']),
          min: min(datas.latterTime['restTime']),
          average: average(datas.latterTime['restTime']),
          median: median(datas.latterTime['restTime'])
        },
        strokeCount: {
          sum: sum(datas.latterTime['strokeCount']),
          max: max(datas.latterTime['strokeCount']),
          min: min(datas.latterTime['strokeCount']),
          average: average(datas.latterTime['strokeCount']),
          median: median(datas.latterTime['strokeCount'])
        }
      }
    };
  }

  setDatasets() {
    const dataSets = {
      allTime: {
        workTime: [],
        restTime: [],
        strokeCount: [],
        labels: [],
        excludeRestTime: []
      },
      firstTime: {
        workTime: [],
        restTime: [],
        strokeCount: [],
        labels: [],
        excludeRestTime: []
      },
      latterTime: {
        workTime: [],
        restTime: [],
        strokeCount: [],
        labels: [],
        excludeRestTime: []
      }
    };
    const timeDatas = JSON.parse(JSON.stringify(this.timeDatas));
    const harf = Math.floor(timeDatas.length / 2);
    for (const timeAnalytics of timeDatas) {
      dataSets.allTime.labels.push(timeAnalytics['rallyNumber']);
      dataSets.allTime.workTime.push(
          Math.floor(timeAnalytics['workTime'] * 10) / 10);
      dataSets.allTime.restTime.push(
          Math.floor(timeAnalytics['restTime'] * 10) / 10);
      dataSets.allTime.strokeCount.push(timeAnalytics['strokeCount']);
      dataSets.allTime.excludeRestTime.push(timeAnalytics['excludeRestTime']);
      let segment;
      timeAnalytics['rallyNumber'] <= harf ? (segment = 'firstTime') :
                                             (segment = 'latterTime');
      dataSets[segment].labels.push(timeAnalytics['rallyNumber']);
      dataSets[segment].workTime.push(
          Math.floor(timeAnalytics['workTime'] * 10) / 10);
      dataSets[segment].restTime.push(
          Math.floor(timeAnalytics['restTime'] * 10) / 10);
      dataSets[segment].strokeCount.push(timeAnalytics['strokeCount']);
      dataSets[segment].excludeRestTime.push(timeAnalytics['excludeRestTime']);
    }
    // console.log(dataSets);
    return dataSets;
  }
  // チャートデータを返す
  setTimeChart() {
    const data = this.setDatasets();
    this.aggregateDatas = this.aggregateTime(data);
    let yAxesTicksMax = this.aggregateDatas['allTime'].workTime.max >
            this.aggregateDatas['allTime'].strokeCount.max ?
        Math.ceil(this.aggregateDatas['allTime'].workTime.max) + 1 :
        Math.ceil(this.aggregateDatas['allTime'].strokeCount.max) + 1;
    if (yAxesTicksMax > 30) {
      yAxesTicksMax = 30;
    }
    const chartData = {};
    for (const key in this.aggregateDatas) {
      if (this.aggregateDatas.hasOwnProperty(key)) {
        chartData[key] = {
          type: 'bar',
          data: {
            labels: data[key]['labels'],
            datasets: [
              {
                type: 'line',
                label: 'ストローク数',
                data: data[key]['strokeCount'],
                borderColor: this.chartColors.sky,
                backgroundColor: this.chartColors.clear
              },
              {
                label: 'ラリー時間',
                data: data[key]['workTime'],
                backgroundColor: this.chartColors.red,
                borderColor: this.chartColors.clear
              },
              {
                label: '休憩時間',
                data: data[key]['restTime'],
                backgroundColor: this.chartColors.lime,
                borderColor: this.chartColors.clear
              }
            ]
          },
          options: {
            title: {display: false, text: 'タイム分析のグラフ'},
            hover: {animationDuration: 0},
            responsive: true,
            maintainAspectRatio: true,
            legend: {
              display: false,
              position: 'bottom',
              labels: {boxWidth: 20, fontSize: 14}
            },
            scales: {
              xAxes: [{
                ticks: {
                  callback: function(value, index, values) {
                    return values.length < 20 ? value :
                                                value % 2 === 0 ? value : '';
                  },
                  fontSize: 14
                }
              }],
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  max: yAxesTicksMax,
                  fontSize: 14
                }
              }]
            }
          }
        };
      }
    }
    return {chartData: chartData, aggregateDatas: this.aggregateDatas};
  }
}
export class AnalyticsFact {
  aggregateFactor: object = new Object();
  factRanks: object = new Object();
  factRate: object = {
    left: {
      rate: {positive: 0, negative: 0, point: 0, factor: 0, count: 0},
      sum: {positive: 0, negative: 0, point: 0, factor: 0, count: 0}
    },
    right: {
      rate: {positive: 0, negative: 0, point: 0, factor: 0, count: 0},
      sum: {positive: 0, negative: 0, point: 0, factor: 0, count: 0}
    }
  };
  allShow = {all: false, shot: false, factor: false};
  factDatas: any = {};
  setFactOutput(factDatas) {
    this.factDatas = Object.keys(factDatas).map(keys => factDatas[keys]);
    for (let index = 0; index < this.factDatas.length; index++) {
      if (this.factDatas[index].pointPlayer) {
        this.factDatas[index].pointSide = this.factDatas[index].playerPosition;
      } else {
        if (this.factDatas[index].playerPosition === 'left') {
          this.factDatas[index].pointSide = 'right';
        }
        if (this.factDatas[index].playerPosition === 'right') {
          this.factDatas[index].pointSide = 'left';
        }
      }
    }
    if (!this.factDatas.length) {
      return null;
    }
    // console.log('factDatas', this.factDatas);
    this.factRanks['side'] =
        this.getFactRank('playerPosition', 'shotName', 'factor', 'pointSide');
    this.factRanks['player'] = this.getFactRank(
        'playerCode', 'playerPosition', 'shotName', 'factor', 'pointSide');
    this.factRanks['shot'] =
        this.getFactRank('playerPosition', 'shotName', 'pointSide');
    this.factRanks['factor'] =
        this.getFactRank('playerPosition', 'factor', 'pointSide');
    this.getFactRate();
    return {factRanks: this.factRanks, factRate: this.factRate};
  }
  getFactRate() {
    for (const sideKey in this.factRate) {
      if (this.factRate.hasOwnProperty(sideKey)) {
        for (let index = 0; index < this.factRanks['side'][sideKey].length;
             index++) {
          if (this.factRanks['side'][sideKey][index][0] ===
              this.factRanks['side'][sideKey][index][3]) {
            // ポジティブ
            this.factRate[sideKey].sum.positive += this.factRanks['side'][
              sideKey
            ][index][this.factRanks['side'][sideKey][index].length - 1];
          } else {
            // ネガティブ
            this.factRate[sideKey].sum.negative += this.factRanks['side'][
              sideKey
            ][index][this.factRanks['side'][sideKey][index].length - 1];
          }
          // 要因関連数
          this.factRate[sideKey].sum.factor += this.factRanks['side'][sideKey][
            index
          ][this.factRanks['side'][sideKey][index].length - 1];
          // 要因数
          this.factRate[sideKey].sum.count++;
          // スコアカウント
          this.factRate[
            this.factRanks['side'][sideKey][index][3]
          ].sum.point += this.factRanks['side'][sideKey][index][
            this.factRanks['side'][sideKey][index].length - 1
          ];
        }
      }
    }
    for (const key in this.factRate['left'].rate) {
      if (this.factRate['left'].rate.hasOwnProperty(key)) {
        this.factRate['left'].rate[key] = this.factRate['left'].sum[key] /
            (this.factRate['left'].sum[key] + this.factRate['right'].sum[key]) *
            100;
        this.factRate['right'].rate[key] = this.factRate['right'].sum[key] /
            (this.factRate['left'].sum[key] + this.factRate['right'].sum[key]) *
            100;
      }
    }
  }

  getFactRank(...factParams) {
    if (!factParams.length) {
      factParams =
          ['playerCode', 'playerPosition', 'shotName', 'factor', 'pointSide'];
    }
    const factDatas = {left: [], right: []};
    for (const data of this.factDatas) {
      factDatas[data['playerPosition']].push(data);
    }
    const side = {left: 'left', right: 'right'};
    const counts: any = {left: {}, right: {}};
    for (const sideKey in side) {
      if (side.hasOwnProperty(sideKey)) {
        for (let i = 0; i < factDatas[sideKey].length; i++) {
          let factParam: any = [];
          for (const param of factParams) {
            factParam.push(factDatas[sideKey][i][param]);
          }
          if (factParam.indexOf('') === -1) {
            factParam = factParam.join('_');
            counts[sideKey][factParam] =
                counts[sideKey][factParam] ? counts[sideKey][factParam] + 1 : 1;
          }
        }
        counts[sideKey] = Object.keys(counts[sideKey])
                              .map(key => [key, counts[sideKey][key]]);
        counts[sideKey].sort(function(a, b) {
          if (a[1] > b[1]) {
            return -1;
          }
          if (a[1] < b[1]) {
            return 1;
          }
          return 0;
        });
        for (let index = 0; index < counts[sideKey].length; index++) {
          const split = counts[sideKey][index][0].split('_');
          counts[sideKey][index] = split.concat(counts[sideKey][index][1]);
        }
        counts['all'] = counts['left'].concat(counts['right']);
        counts['all'].sort(function(a, b) {
          if (a[factParams.length] > b[factParams.length]) {
            return -1;
          }
          if (a[factParams.length] < b[factParams.length]) {
            return 1;
          }
          return 0;
        });
      }
    }
    return counts;
  }
  rank(value, arrs, updown = 0) {
    let i, thisrank, array_length, sign;
    sign = updown === 0 ? 1 : -1;
    thisrank = 1;
    array_length = arrs.length;
    for (i = 0; i < array_length; i++) {
      if (sign * (arrs[i] - value) > 0) {
        thisrank++;
      }
    }
    return thisrank;
  }
}
