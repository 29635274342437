import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
// tslint:disable-next-line:max-line-length
import {ActionSheetController, AlertController, Config, Events, LoadingController, ModalController, PickerController, Platform, ToastController} from '@ionic/angular';

@Injectable({providedIn: 'root'})
export class ApiService {
  public loading = null;  // ローディング表示用
  constructor(
      public alertCtrl: AlertController, public toastCtrl: ToastController,
      public platform: Platform, public events: Events,
      public loadingCtrl: LoadingController,
      public actionSheetCtrl: ActionSheetController,
      public modalController: ModalController, public config: Config,
      public pickerCtrl: PickerController, public router: Router) {}
  // ローディングを開始
  // async startLoading(
  //   options: { spinner?: string; content?: string; duration?: number } = {}
  // ) {
  //   this.loading = this.loadingCtrl.create(options);
  //   return await this.loading.present();
  // }
  // ローディングを止める
  public async stopLoading() {
    if (this.loading === null) {
      return;
    }
    await this.loading.dismiss().catch(
        error => {
            // console.log(error);
        });
    this.loading = null;
    return;
  }
  public async startLoading(options = {}) {
    this.loading = await this.loadingCtrl.create(options);
    return await this.loading.present();
  }

  // エラーアラート
  public async errorAlert(error) {
    const alert = await this.alertCtrl.create(
        {header: 'エラー', message: error.message, buttons: ['OK']});
    await alert.present();
  }
  // 確認アラート
  public async presentAlert(value: {
    title?: string;
    message?: string;
    buttons?: string[];
    enableBackdropDismiss?: boolean;
  }) {
    const alert = await this.alertCtrl.create({
      header: value['title'] ? value.title : '確認',
      message: value['message'] ? value.message : '',
      buttons: value['buttons'] ? value.buttons : ['OK'],
      backdropDismiss:
          value['enableBackdropDismiss'] ? value.enableBackdropDismiss : false
    });
    await alert.present();
  }
  // 警告アラート
  public async presentConfirm(
      title, message, buttons?: {text: [string, string]}|null,
      enableBackdropDismiss = true) {
    return new Promise<any>(async resolve => {
      const alert = await this.alertCtrl.create({
        backdropDismiss: enableBackdropDismiss,
        header: title,
        message: message,
        buttons: [
          {
            text: buttons ? buttons.text[0] : 'キャンセル',
            role: 'cancel',
            handler: () => {
              return resolve(false);
            }
          },
          {
            text: buttons ? buttons.text[1] : 'OK',
            handler: () => {
              return resolve(true);
            }
          }
        ]
      });
      await alert.present().catch(() => {
        return resolve(false);
      });
    });
  }

  async presentAlertPrompt(header, inputs, buttons) {
    const alert = await this.alertCtrl.create(
        {header: header, inputs: inputs, buttons: buttons});
    await alert.present();
  }

  // カスタムアラート
  public async setAlert(title: string, ...buttons: any[]) {
    const buttons_tmp = [];
    for (const button of buttons) {
      if (Object.keys(button).length) {
        buttons_tmp.push({
          text: button.text ? button.text : '',
          role: button.role ? button.role : '',
          handler: button.handler
        });
      }
    }
    const alert =
        await this.alertCtrl.create({header: title, buttons: buttons_tmp});
    await alert.present();
  }

  // アクションシート
  public async presentActionSheet(
      option: {
        header?: string;
        subHeader?: string;
        cssClass?: string;
        backdropDismiss?: boolean;
      },
      ...buttons: any[]) {
    const buttons_tmp = [];
    for (const button of buttons) {
      if (Object.keys(button).length && !button.disabled) {
        buttons_tmp.push({
          text: button.text ? button.text : '',
          role: button.role ? button.role : '',
          handler: button.handler ? button.handler : () => {}
        });
      }
    }
    const actionSheet = await this.actionSheetCtrl.create({
      header: option.header,
      subHeader: option.subHeader,
      cssClass: option.cssClass,
      backdropDismiss: option.backdropDismiss,
      buttons: buttons_tmp
    });
    await actionSheet.present();
  }
  // モーダル
  public async presentModal(page, value?) {
    return new Promise<any>(async resolve => {
      const modal = await this.modalController.create({
        component: page,
        componentProps: {value: value},
        showBackdrop: false,
        backdropDismiss: false
      });
      await modal.present();
      const {data} = await modal.onDidDismiss();
      // console.log(data);
      resolve(data);
    });
  }
  // ホームに戻る
  goHome() {
    this.router.navigate(['/home']);
  }
  // ページの移動
  navigate(path) {
    this.router.navigate([path]);
  }

  // トーストメッセージ
  public async presentToast(
      message: string, duration: number = 3,
      position: 'top'|'bottom'|'middle' = 'top', showCloseButton = false) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: showCloseButton ? 0 : duration * 1000,
      position: position,
      showCloseButton: showCloseButton,
      closeButtonText: 'OK'
    });
    toast.present();
  }
  // ピッカー
  public async openPicker(
      buttons, columns, cssClass = '', backdropDismiss = false) {
    // [{
    //   name: 'days',
    //   options: [
    //     {text: '1', value: 1}
    //   ]
    // },
    //  {
    //    name: 'years',
    //    options: [
    //      {text: '1992', value: 1992}
    //    ]
    //  },
    // ]
    const picker = await this.pickerCtrl.create({
      buttons: buttons,
      columns: columns,
      backdropDismiss: backdropDismiss,
      cssClass: cssClass
    });
    await picker.present();
    return picker;
  }
  // 非同期処理
  wait(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  // イベント登録
  public setEvent(topic, handler) {
    this.events.subscribe(topic, handler);
  }
  // イベント通知
  public publishEvent(topic, ...eventData) {
    this.events.publish(topic, eventData);
  }
  // イベント解除
  public unsubscribeEvent(topic, handler?) {
    this.events.unsubscribe(topic, handler);
  }
}
