
import {Component} from '@angular/core';
import {Firebase} from '@ionic-native/firebase/ngx';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {Platform} from '@ionic/angular';
import * as firebase from 'firebase/app';

import {firebaseConfig} from './credentials';
import {FirebaseService} from './service/firebase.service';

declare var window: any;

@Component({selector: 'app-root', templateUrl: 'app.component.html'})
export class AppComponent {
  webVersion = 3;
  public appPages = [
    {title: 'ホーム', url: '/home', icon: 'home'},
    {title: '分析依頼', url: '/request', icon: 'send'}
    // {title: 'ログイン', url: '/login', icon: 'log-in'}
  ];

  constructor(
      private platform: Platform, private splashScreen: SplashScreen,
      private statusBar: StatusBar, private ngxFirebase: Firebase,
      public firebaseService: FirebaseService) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
        if (this.platform.is('ios')) {
          this.ngxFirebase.hasPermission().then(data => {
            this.ngxFirebase.grantPermission();
          });
        }
      }
    });
  }
  locationReload(bool = false) {
    location.reload(bool);
  }
}
