import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AdminGuard} from './guard/admin.guard';
import {LoggedinGuard} from './guard/loggedin.guard';
import {LoginGuard} from './guard/login.guard';

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'}, {
    path: 'home',
    loadChildren: './page/home/home.module#HomePageModule',
    canActivate: [LoginGuard]
  },
  {
    path: 'player/:id',
    loadChildren: './page/home/home.module#HomePageModule',
    canActivate: [LoginGuard]
  },
  {
    path: 'edit/:id',
    loadChildren: './page/home/home.module#HomePageModule',
    canActivate: [LoginGuard]
  },
  {
    path: 'edit/request/:id',
    loadChildren: './page/home/home.module#HomePageModule',
    canActivate: [LoginGuard]
  },
  {
    path: 'login',
    loadChildren: './page/login/login.module#LoginPageModule',
    canActivate: [LoggedinGuard]
  },
  {
    path: 'request',
    loadChildren: './page/request/request.module#RequestPageModule',
    canActivate: [LoginGuard]
  },
  {
    path: 'request/:id',
    loadChildren: './page/request/request.module#RequestPageModule',
    canActivate: [LoginGuard]
  },
  {
    path: 'admin',
    loadChildren: './page/admin/admin.module#AdminPageModule',
    canActivate: [AdminGuard]
  }
];

@NgModule({imports: [RouterModule.forRoot(routes)], exports: [RouterModule]})
export class AppRoutingModule {
}
