import {NgModule} from '@angular/core';
import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {ServiceWorkerModule} from '@angular/service-worker';
import {Firebase} from '@ionic-native/firebase/ngx';
import {GooglePlus} from '@ionic-native/google-plus/ngx';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {SafariViewController} from '@ionic-native/safari-view-controller/ngx';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {AngularFirestoreModule} from 'angularfire2/firestore';
import {AngularFireStorageModule} from 'angularfire2/storage';

import {environment} from '../environments/environment';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {firebaseConfig} from './credentials';
import {UsersListPageModule} from './page/users-list/users-list.module';
import {FirebaseService} from './service/firebase.service';
import {AnalyticsFact, AnalyticsTime} from './utils/analyticsOutput';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, IonicModule.forRoot(), AppRoutingModule,
    ServiceWorkerModule.register(
        'ngsw-worker.js', {enabled: environment.production}),
    AngularFireModule.initializeApp(firebaseConfig), AngularFirestoreModule,
    AngularFireAuthModule, AngularFireStorageModule, UsersListPageModule
  ],
  providers: [
    StatusBar, SplashScreen,
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    FirebaseService, Firebase, GooglePlus, SafariViewController, InAppBrowser,
    AnalyticsFact, AnalyticsTime
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
