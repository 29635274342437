import 'firebase/auth';

import {ApplicationRef, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Platform} from '@ionic/angular';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from 'angularfire2/firestore';
import * as firebase from 'firebase/app';
import {Subscription} from 'rxjs';

import {ApiService} from '../service/api.service';
import {NativeService} from '../service/native.service';

@Injectable({providedIn: 'root'})
export class FirebaseService {
  // firestore関係 //
  storeCollection: object =
      new Object();  // firestoreのcollectionパスを格納する場所
  storeDoc: object = new Object();  // firestoreのdocumentパスを格納する場所
  // ログイン情報 //
  isLogin = false;        // ログインしているかどうか
  loadLoginInit = false;  // ログイン初期化が完了したらTrue
  // アカウント情報 //
  authProfile: object = new Object();   // firebase.authのデータ
  authProvider: object = new Object();  // firebase.authのproviderデータ
  storeProfile: any;                    // firestoreのユーザーデータ
  // 分析データ //
  analyticsPublicList: any = [];
  analyticsWritersList: any = [];
  analyticsRequestList: any = null;  // 分析依頼履歴
  // ユーザーリスト
  usersList: object[] = [];  // ユーザー一覧情報
  // その他 //
  _subs: Subscription[] = [];  // subscriptionを一括して管理する場所
  errorFlag = false;           // エラーフラグ
  analyticsData = {};          // アナリティクスデータ
  config: any = null;
  // 管理者用 //
  adminProfile: object = new Object();  // 管理者ログイン時のprofileを格納
  constructor(
      public firestore: AngularFirestore, public platform: Platform,
      public applicationRef: ApplicationRef, public api: ApiService,
      public native: NativeService, public router: Router) {
    this.loginInit().then(async () => {
      this.loadLoginInit = true;
    });
  }
  // ログインしているかを確認する
  async loginCheck() {
    return new Promise<any>(async resolve => {
      const user = firebase.auth().currentUser;
      if (user) {
        return resolve(true);
      } else {
        return resolve(false);
      }
    });
  }
  // ログイン時の初期設定
  async loginInit() {
    return new Promise<any>(async resolve => {
      await this.platform.ready();
      await this.unsubscribe();
      // ログインしてるかどうか判定
      await firebase.auth().onAuthStateChanged(async user => {
        if (!user) {
          // ログインしていない時
          if (this.isLogin) {
            await this.unsubscribe();
          }
          this.isLogin = false;
          this.adminProfile = {};
          this.authProvider = {};
          this.authProfile = {};
          this.storeProfile = {};
          this.analyticsData = {};
          this.analyticsPublicList = [];
          this.analyticsWritersList = [];
          this.analyticsRequestList = [];
          this.usersList = [];
          this.config = null;
          this.loadLoginInit = false;
          this.api.publishEvent('firebaseProvider:onAuthStateChanged', false);
          return resolve(false);
        } else {
          this.firestore.firestore.enableNetwork();
          // ログインしている時
          if (user['providerData'][0]['providerId'] === 'password' &&
              !user.emailVerified) {
            // 認証前のメールアカウントがログインしていたらログアウトする
            return await this.logoutUser();
          }
          this.authProfile = user;
          this.authProvider = user['providerData'][0];
          this.setStorePath();
          let getStoreProfile = await this.getStoreProfile();
          if (user['providerData'][0]['providerId'] !== 'password' &&
              !getStoreProfile) {
            this.api.startLoading({message: 'アカウントの登録を確認中'});
            for (let index = 0; index < 3; index++) {
              await this.api.wait(3000);
              getStoreProfile = await this.getStoreProfile();
              if (!!getStoreProfile) {
                this.api.stopLoading();
                this.api.presentToast(
                    'アカウントの登録が完了しました', 3, 'middle');
                break;
              }
            }
            this.api.stopLoading();
          }
          if (!getStoreProfile) {
            this.api.presentToast(
                'ログインに失敗しました。再度ログインをしてください。', 3,
                'middle');
            return this.logoutUser();
          }
          this.getStoreCollection(
              'analyticsPublicList',
              this.storeCollection['analyticsPublicList']);
          this.getStoreCollection(
              'analyticsWritersList',
              this.storeCollection['analyticsWritersList']);
          this.getStoreCollection(
              'analyticsRequestList',
              this.storeCollection['analyticsRequestList']);
          // this.getStoreCollection('usersList',
          // this.storeCollection['users']);
          this.getStoreDoc('config', this.setStoreDoc('config/bsion'));
          this.isLogin = true;
          // firebase.auth()
          //     .currentUser.getIdTokenResult()
          //     .then((idTokenResult) => {
          //       console.log(idTokenResult, this.authProfile);
          //     })
          //     .catch((error) => {
          //       console.log(error);
          //     });
          // this.api.presentToast(`ログインしました(${user.displayName})`);
          this.api.publishEvent('firebaseProvider:onAuthStateChanged', true);
          return resolve(true);
        }
      });
    });
  }
  // パスワードログイン処理
  loginUser(email: string, password: string): Promise<any> {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }
  // ログアウト
  async logoutUser(): Promise<any> {
    await this.api.startLoading({message: 'ログアウト中'});
    if (this.platform.is('cordova')) {
      await this.firestore.firestore.disableNetwork();
      await this.native.googlePlus.logout().catch(async () => {
        await this.api.stopLoading();
        await firebase.auth().signOut();
        return this.router.navigateByUrl('/login');
      });
    }
    await this.firestore.firestore.disableNetwork();
    await firebase.auth().signOut();
    await this.api.stopLoading();
    return this.router.navigateByUrl('/login');
  }

  // firebase.auth処理 //

  // 新規登録
  async signupUser(email: string, password: string, username): Promise<any> {
    await firebase.auth()
        .createUserWithEmailAndPassword(email, password)
        .catch(error => {
          throw new Error(error);
        });
    const user = firebase.auth().currentUser;
    await user.updateProfile({displayName: username, photoURL: null});
    // 認証メールの送信
    await user.sendEmailVerification().catch(error => {});
    return Promise.resolve(true);
  }
  // リセットパスワード
  resetPassword(email: string): Promise<void> {
    return firebase.auth().sendPasswordResetEmail(email);
  }
  // ユーザー情報の更新
  updateProfile(newProfile, password?) {
    return new Promise<any>(async (resolve, reject) => {
      const user = firebase.auth().currentUser;
      if (newProfile.displayName !== this.authProfile['displayName'] ||
          newProfile.photoURL !== this.authProfile['photoURL']) {
        // ユーザーネーム、画像URLの変更の場合はこちら
        await user.updateProfile({
          displayName: newProfile.displayName,
          photoURL: newProfile.photoURL
        });
        if (newProfile.photoURL) {
          await this.updateStoreProfile({photoURL: newProfile.photoURL});
        } else {
          await this.updateStoreProfile({displayName: newProfile.displayName});
        }
        if (newProfile.email === this.storeProfile['email']) {
          // メールアドレスの変更がない場合の処理
          resolve(true);
          return this.api.presentToast('変更を保存しました');
        }
      }
      if (newProfile.email !== this.storeProfile['email']) {
        if (newProfile.providerId === 'password') {
          // パスワード認証時のメールアドレス変更処理
          const credential = firebase.auth.EmailAuthProvider.credential(
              this.authProfile['email'], password);
          let errorCheck = false;
          // パスワード再認証
          await user.reauthenticateWithCredential(credential).catch(error => {
            errorCheck = true;
            this.api.errorAlert(error);
            return reject();
          });
          if (errorCheck) {
            return;
          }
          // メールアドレスの更新
          await user.updateEmail(newProfile.email).catch(error => {
            errorCheck = true;
            this.api.errorAlert(error);
            return reject();
          });
          if (errorCheck) {
            return;
          }
          // 更新先に認証メールの送信
          await user.sendEmailVerification().catch(error => {
            errorCheck = true;
            this.api.errorAlert(error);
            return reject();
          });
          if (errorCheck) {
            return;
          }
          // トーストを表示
          this.api.presentToast(
              '変更したメールアドレスに認証メールを送信しました');
          resolve(true);
          // ログアウトする
          return await this.logoutUser();
        } else {
          // SNS認証時のメールアドレス変更処理
          await this.updateStoreProfile({email: newProfile.email});
          resolve(true);
          // トーストを表示
          return this.api.presentToast('変更を保存しました');
        }
      }
    });
  }
  // 画像アップロード（firebase storage）
  uploadImage(image64) {
    return new Promise<any>((resolve, reject) => {
      const storageRef = firebase.storage().ref();
      const imageRef = storageRef.child('images').child('users').child(
          this.authProfile['uid']);
      imageRef.putString(image64, 'data_url')
          .then(
              snapshot => {
                imageRef.getDownloadURL().then(url => {
                  resolve(url);
                });
              },
              err => {
                reject(err);
              });
    });
  }
  // ビデオアップロード（firebase storage）
  uploadVideo(file: any, videoId) {
    return new Promise<any>((resolve, reject) => {
      // console.log(file.type, file);
      if (file.type !== 'video/mp4') {
        return;
      }
      const publicList = this.authProfile['uid'];
      const storageRef = firebase.storage().ref();
      const videoRef =
          storageRef.child('videos').child('mp4').child(videoId + '.mp4');
      const uploadTask = videoRef.put(file, {
        customMetadata: {
          owner: this.authProfile['uid'],
          publicList: publicList,
          videoId: videoId
        }
      });
      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,  // or 'state_changed'
          (snapshot: any) => {
            // Get task progress, including the number of bytes uploaded and the
            // total number of bytes to be uploaded
            const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.api.publishEvent('uploadTask:progress', progress);
            // console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED:  // or 'paused'
                // console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING:  // or 'running'
                // console.log('Upload is running');
                break;
            }
          },
          (error: any) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                this.api.presentToast('アップロードすることができませんでした');
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                this.api.presentToast('アップロードがキャンセルされました');
                // User canceled the upload
                break;
              case 'storage/unknown':
                this.api.presentToast('アップロードが失敗しました');
                // Unknown error occurred, inspect error.serverResponse
                break;
              default:
                this.api.presentToast('アップロードが失敗しました');
                break;
            }
            reject(error);
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then(
                async (downloadURL) => {
                  // console.log('File available at', downloadURL);
                  const metaData = await videoRef.getMetadata().catch(() => {});
                  // console.log(metaData, downloadURL);
                  resolve({
                    downloadURL: downloadURL,
                    storagePath: videoRef.fullPath
                  });
                });
          });
    });
  }
  downloadVideo(path = '') {
    return new Promise<any>((resolve, reject) => {
      if (!path) {
        resolve(null);
        return;
      }
      const storageRef = firebase.storage().ref();
      storageRef.child(path)
          .getDownloadURL()
          .then((url) => {
            // console.log(url);
            resolve(url);
            return;
          })
          .catch((error) => {
            // console.log(error.code);
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/object-not-found':
                // File doesn't exist
                this.api.presentToast('動画が存在しません');
                break;
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                this.api.presentToast('動画を読み込むことができませんでした');
                break;
              case 'storage/canceled':
                // User canceled the upload
                this.api.presentToast('動画の読み込み中にエラーが発生しました');
                break;
              case 'storage/unknown':
                // Unknown error occurred, inspect the server response
                this.api.presentToast('動画を読み込めません');
                break;
            }
            reject(error);
          });
    });
  }
  // storageRef
  storageRef() {
    return firebase.storage().ref();
  }
  // メタデータを更新する
  updateMetadata(storagePath, newMetadata) {
    firebase.storage().ref().child(storagePath).updateMetadata(newMetadata);
  }
  // ログアウト時にunsubscribe処理を行う
  async unsubscribe() {
    return new Promise<any>(async resolve => {
      await firebase.auth().onAuthStateChanged(user => {
        if (!user) {
          // ログアウト時の処理
          for (const sub of this._subs) {
            if (sub) {
              sub.unsubscribe();
            }
          }
          this._subs = [];
          return resolve(true);
        }
      });
      return resolve(false);
    });
  }

  // firestore処理 //

  // エラー時の処理
  async errorInit() {
    this.errorFlag = true;
    if (!this.isLogin) {
      return await this.unsubscribe();
    }
  }
  // profileの読み込み(firestore)
  async getStoreProfile(uid = this.authProfile['uid']) {
    if (uid) {
      return await this.getStoreDoc('storeProfile', this.storeDoc['profile']);
    }
    return Promise.resolve(false);
  }

  // 共有設定の読み込み(firestore)docIDが存在しない場合はpromiseでfalseを返す
  async getStoreDoc(key: any, path: AngularFirestoreDocument) {
    return new Promise<any>((resolve, reject) => {
      const sub: Subscription = path.snapshotChanges().subscribe(
          data => {
            switch (key) {
              case 'analyticsData':
              case 'configBsion':
              case 'bsion-public-request':
                sub.unsubscribe();
                if (data.payload.exists) {
                  if (data.payload.data().archive) {
                    resolve(false);
                  } else {
                    resolve(data.payload.data());
                  }
                } else {
                  resolve(data.payload.exists);
                }
                return;
              default:
                if (data.payload.data() && key) {
                  this[key] = data.payload.data();
                  this._subs.push(sub);
                  resolve(data.payload.exists);
                  return;
                }
                break;
            }
            sub.unsubscribe();
            resolve(data.payload.exists);
          },
          async (error: any) => {
            await this.errorInit();
            reject(error);
          });
    });
  }
  // 共有設定の読み込み(firestore)
  getStoreCollection(key: any, path: AngularFirestoreCollection) {
    return new Promise<any>((resolve, reject) => {
      const sub: Subscription = path.snapshotChanges().subscribe(
          data => {
            if (data.length) {
              // 通常のデータ取得作業
              data = Object.keys(data).map(
                  keys => data[keys]['payload'].doc.data());
              switch (key) {
                case 'usersList':
                case 'analyticsPublicList':
                case 'analyticsWritersList':
                case 'analyticsRequestList':
                  break;
                case 'videoIdList':
                  sub.unsubscribe();
                  resolve(data[0]['infoVideo']);
                  return;
                default:
                  break;
              }
              // console.log(data);
              if (key) {
                this[key] = data;
                this._subs.push(sub);
              } else {
                sub.unsubscribe();
                resolve(data);
              }
              this.api.publishEvent('getStoreCollection:' + key, true, data);
            } else {
              // データが無いとき
              switch (key) {
                case 'analyticsPublicList':
                case 'analyticsWritersList':
                  data = [];
                  this[key] = data;
                  this._subs.push(sub);
                  break;
                case 'analyticsRequestList':
                  data = null;
                  this[key] = data;
                  this._subs.push(sub);
                  break;
                case 'videoIdList':
                case 'usersList':
                  sub.unsubscribe();
                  resolve(null);
                  return;
                default:
                  sub.unsubscribe();
                  break;
              }
              return resolve(false);
            }
            return resolve(true);
          },
          (error: any) => {
            this.errorInit();
            return reject(error);
          });
    });
  }
  // プロフィールの更新(firestore)
  updateStoreProfile(items) {
    return new Promise(async (resolve, reject) => {
      await this.platform.ready();
      // firestoreのユーザードキュメントの取得
      this.storeDoc['profile'] =
          this.firestore.doc('users/' + this.authProfile['uid']);
      // ユーザープロフィールの更新
      await this.storeDoc['profile'].update(items).catch(error => {
        console.error(error);
      });
      // photoURL、画像の更新の場合
      if (items.photoURL) {
        return await setTimeout(async () => {
          await this.getStoreProfile();
          // this.applicationRef.tick();
          resolve(true);
        }, 1000);
      }
      // ユーザーネーム、メールアドレスの変更の場合
      await this.getStoreProfile();
      // this.applicationRef.tick();
      resolve(true);
      return;
    });
  }
  // 権限の有無をチェック
  checkPermit(value) {
    if (!this.storeProfile) {
      return false;
    }
    if (!this.storeProfile.hasOwnProperty('permit')) {
      return false;
    }
    return (this.storeProfile.permit.indexOf(value) >= 0);
  }
  // firestoreのパスを設定
  setStorePath(uid = this.authProfile['uid']) {
    const defaultLimit = 100;
    this.storeCollection = {
      bision: this.firestore.collection('users/' + uid + '/bsion'),
      analyticsPublicList: this.firestore.collection(
          'bsion-public-analytics',
          ref => ref.where('exists.bsionAnalytics', '==', true)
                     .where('archive', '==', false)
                     .where('publicList', 'array-contains', uid)
                     .orderBy('createTime', 'desc')
                     // .startAfter(this.latestEntry["resultList"])
                     .limit(defaultLimit)),
      analyticsWritersList: this.firestore.collection(
          'bsion-public-analytics',
          ref => ref.where('exists.bsionAnalytics', '==', true)
                     .where('archive', '==', false)
                     .where('writers.bsionAnalytics', '==', uid)
                     .orderBy('createTime', 'desc')
                     // .startAfter(this.latestEntry["resultList"])
                     .limit(defaultLimit)),
      analyticsRequestList: this.firestore.collection(
          'bsion-public-request',
          ref => ref.where('archive', '==', false)
                     .where('uid', '==', uid)
                     .orderBy('createTime', 'desc')
                     .limit(defaultLimit)),
      users: this.firestore.collection('users')
    };
    this.storeDoc = {profile: this.firestore.doc('users/' + uid)};
  }
  // firestoreのDocmentパスを作成
  setStoreDoc(path) {
    return this.firestore.doc(path);
  }
  // firestoreのCollectionパスを作成
  setStoreCollection(path, ref?) {
    return this.firestore.collection(path, ref);
  }
  // firestoreのサーバータイムを取得
  setStoreTimestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }
  // 配列データの指定した値を削除
  arrayRemove(value) {
    return firebase.firestore.FieldValue.arrayRemove(value);
  }
  // 配列に指定した値を追加
  arrayUnion(value) {
    return firebase.firestore.FieldValue.arrayUnion(value);
  }
  // firestoreのdocIDを生成
  createId() {
    return this.firestore.createId();
  }
}
