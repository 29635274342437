import {Component, Input, OnInit} from '@angular/core';
import {NavParams} from '@ionic/angular';

import {ApiService} from '../../service/api.service';
import {FirebaseService} from '../../service/firebase.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.page.html',
  styleUrls: ['./users-list.page.scss'],
})
export class UsersListPage implements OnInit {
  @Input() value: number;
  users = [];
  publicList = [];
  constructor(
      public firebase: FirebaseService, public api: ApiService,
      navParams: NavParams) {
    this.api.setEvent('getStoreCollection:usersList', () => {
      for (const users of this.firebase.usersList) {
        if (this.value['publicList'].indexOf(users['uid']) >= 0) {
          users['isChecked'] = true;
        }
      }
    });
  }
  async ngOnInit() {}
  modalDismiss(publicList = null) {
    if (publicList) {
      this.publicList = publicList;
    } else {
      for (const users of this.firebase.usersList) {
        if (users['isChecked']) {
          this.publicList.push(users['uid']);
        }
      }
    }
    // Dismiss the top modal returning some data object
    this.api.modalController.dismiss({'publicList': this.publicList});
  }
  ionChange() {
    // console.log(this.firebase.usersList);
  }
  async ionViewDidEnter() {
    this.adminInit();
  }
  async adminInit() {
    await this.firebase.getStoreCollection(
        'usersList', this.firebase.storeCollection['users']);
  }
  checked(uid) {
    return (this.value['publicList'].indexOf(uid) >= 0);
  }
}
